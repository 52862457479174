/* ============================== */
/* 1. Modal Overlay */
/* ============================== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
  opacity: 0;
  transition: opacity 0.4s ease-out;
}

.modal-overlay.show {
  opacity: 1;
}

/* ============================== */
/* 2. Modal Content */
/* ============================== */
.modal-content {
  background: linear-gradient(135deg, 
    rgba(249, 249, 249, 0.95) 0%, 
    rgba(240, 240, 240, 0.9) 100%);
  padding: 40px 50px;
  border-radius: 20px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
  transform: translateY(20px) scale(0.98);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), 
              opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
}

.modal-content.show {
  transform: translateY(0) scale(1);
  opacity: 1;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .modal-content {
    background: linear-gradient(135deg, 
      rgba(30, 30, 30, 0.95) 0%, 
      rgba(18, 18, 18, 0.9) 100%);
    color: #f5f5f5;
  }
}

.modal-content h2 {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@media (prefers-color-scheme: dark) {
  .modal-content h2 {
    color: #f5f5f5;
  }
}

/* ============================== */
/* 3. Input Fields */
/* ============================== */
.input-group {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

.modal-input {
  padding: 15px 20px;
  font-size: 16px;
  width: 100%;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  color: #333;
}

.modal-input:focus {
  border-color: #007bff;
  box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
  outline: none;
  background-color: white;
}

.input-label {
  position: absolute;
  left: 20px;
  top: 15px;
  color: #888;
  pointer-events: none;
  transition: all 0.3s ease;
  font-size: 16px;
  opacity: 0;
}

.modal-input:focus + .input-label,
.modal-input:not(:placeholder-shown) + .input-label {
  top: -12px;
  left: 15px;
  font-size: 14px;
  color: #007bff;
  padding: 0 5px;
  background-color: white;
  opacity: 1;
}

@media (prefers-color-scheme: dark) {
  .modal-input {
    background-color: rgba(30, 30, 30, 0.8);
    border-color: #444;
    color: #f0f0f0;
  }
  
  .modal-input:focus {
    background-color: #2a2a2a;
    border-color: #bb86fc;
    box-shadow: 0 4px 15px rgba(187, 134, 252, 0.2);
  }
  
  .input-label {
    color: #aaa;
  }
  
  .modal-input:focus + .input-label,
  .modal-input:not(:placeholder-shown) + .input-label {
    color: #bb86fc;
    background-color: #2a2a2a;
  }
}

/* ============================== */
/* 4. Buttons */
/* ============================== */
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-btn {
  padding: 14px 24px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  flex: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.join-btn {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
}

.join-btn:hover {
  background: linear-gradient(135deg, #0069d9, #004ca3);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 123, 255, 0.3);
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #555;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: dark) {
  .join-btn {
    background: linear-gradient(135deg, #bb86fc, #6200ea);
  }
  
  .join-btn:hover {
    background: linear-gradient(135deg, #c599ff, #7722ff);
    box-shadow: 0 10px 20px rgba(187, 134, 252, 0.3);
  }
  
  .cancel-btn {
    background-color: #333;
    color: #e0e0e0;
  }
  
  .cancel-btn:hover {
    background-color: #444;
  }
}

/* ============================== */
/* 5. Close Button */
/* ============================== */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #666;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.close-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #f44336;
  transform: rotate(90deg);
}

@media (prefers-color-scheme: dark) {
  .close-btn {
    color: #bbb;
  }
  
  .close-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/* ============================== */
/* 6. Responsive Design */
/* ============================== */
@media (max-width: 600px) {
  .modal-content {
    padding: 30px 25px;
    width: 95%;
  }
  
  .modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .modal-input {
    padding: 12px 15px;
    font-size: 14px;
  }
  
  .input-label {
    font-size: 14px;
  }
  
  .modal-buttons {
    flex-direction: column;
    gap: 10px;
  }
  
  .modal-btn {
    margin: 5px 0;
    padding: 12px 20px;
  }
}