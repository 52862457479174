/* RoomPage.module.css */

/* ============================= */
/* 1. General Container */
/* ============================= */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
/* Fix for selection visibility across multiple lines */
.room-container :global(.cm-line .cm-selectionBackground) {
  display: inline-block !important;
  background-color: #3182ce !important;
  opacity: 0.7 !important;
}

/* Special fix for first line selection visibility */
.room-container :global(.cm-line:first-of-type .cm-selectionBackground),
.room-container :global(.cm-line:first-child .cm-selectionBackground) {
  background-color: #3182ce !important;
  opacity: 0.7 !important;
  display: inline-block !important; /* Force display */
  z-index: 20 !important; /* Even higher z-index for first line */
}
.room-container :global(.cm-line:last-of-type .cm-selectionBackground),
.room-container :global(.cm-line:last-child .cm-selectionBackground) {
  background-color: #3182ce !important;
  opacity: 0.7 !important;
  display: inline-block !important;
  z-index: 20 !important;
  position: relative !important;
  width: 100% !important;
}
.room-container :global(.cm-line:empty .cm-selectionBackground) {
  min-width: 100% !important;
  display: block !important;
  height: 1.5em !important;
}
/* Ensure consistent selection appearance across lines */
.room-container :global(.cm-scroller .cm-selectionBackground) {
  background-color: #3182ce !important;
  mix-blend-mode: multiply !important; /* Help with visibility across backgrounds */
}

/* Reset any special styling for the active line when it's also selected */
.room-container :global(.cm-activeLine .cm-selectionBackground) {
  background-color: #3182ce !important; /* Same color as other selections */
  opacity: 0.7 !important;
  border-left: none !important; /* Remove any special active line styling */
}

/* Force visibility of selected areas even with syntax highlighting */
.room-container :global(.cm-selectionBackground *) {
  background-color: transparent !important; /* Let parent background show through */
  color: white !important; /* High contrast text color */
}
.room-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  position: relative;
  transition: background-color 0.4s ease, color 0.4s ease;
  font-family: 'Poppins', sans-serif;
  background-image: radial-gradient(circle at 1% 1%, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
}

.room-container.light {
  background-color: #fafafa;
  color: #2d3748;
}

.room-container.dark {
  background-color: #121212;
  color: #e2e8f0;
  background-image: radial-gradient(circle at 1% 1%, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
}

.connection-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.95);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 400px;
}

.room-container.dark .connection-status {
  background: rgba(30, 30, 30, 0.95);
  border: 1px solid rgba(70, 70, 70, 0.2);
}

.connecting-message {
  color: #3182ce;
  font-weight: 500;
}

.failed-message {
  color: #e53e3e;
  font-weight: 500;
}

.retry-button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(49, 130, 206, 0.3);
}

.retry-button:hover {
  background-color: #2c5282;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(49, 130, 206, 0.4);
}

/* ============================= */
/* 2. Header */
/* ============================= */
.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  background-color: inherit;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 100;
  gap: 15px;
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.room-container.dark .header {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.logo-container {
  display: flex;
  align-items: center;
}

.syncrolly-logo {
  max-width: 60px;
  height: auto;
  margin-right: 0;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), filter 0.3s ease;
  filter: brightness(0) invert(0);
  border-radius: 12px;
}

.refresh-note {
  text-align: center;
  padding: 15px;
  color: #4a5568;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  max-width: 700px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.room-container.light .refresh-note {
  background-color: rgba(255, 255, 255, 0.8);
  color: #4a5568;
}

.room-container.dark .refresh-note {
  background-color: rgba(30, 30, 30, 0.6);
  color: #e2e8f0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.syncrolly-logo:hover {
  transform: scale(1.08) rotate(2deg);
}

.room-container.light .syncrolly-logo {
  filter: brightness(0) invert(0);
}

.room-container.dark .syncrolly-logo {
  filter: brightness(0) invert(1);
}

.room-container.dark label {
  color: #e2e8f0;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: inherit;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.chat-toggle {
  display: flex;
  align-items: center;
  gap: 15px;
}

.toggle-btn {
  background: linear-gradient(135deg, #38a169, #2f855a);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(56, 161, 105, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.toggle-btn.editable {
  background: linear-gradient(135deg, #38a169, #2f855a);
  box-shadow: 0 4px 10px rgba(56, 161, 105, 0.3);
}

.toggle-btn.viewOnly {
  background: linear-gradient(135deg, #e53e3e, #c53030);
  box-shadow: 0 4px 10px rgba(229, 62, 62, 0.3);
}

.toggle-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.toggle-btn.editable:hover {
  background: linear-gradient(135deg, #2f855a, #276749);
}

.toggle-btn.viewOnly:hover {
  background: linear-gradient(135deg, #c53030, #9b2c2c);
}

.toggle-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.toggle-btn .icon {
  margin-right: 8px;
  font-size: 1rem;
}

/* Chat, Files, Download Buttons */
.chat-btn,
.files-btn,
.download-btn {
  padding: 10px 20px;
  background: linear-gradient(135deg, #3182ce, #2b6cb0);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(49, 130, 206, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.download-btn {
  padding: 10px 20px;
}

.chat-btn::before,
.files-btn::before,
.download-btn::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.chat-btn::before {
  content: "\f086";
}

.files-btn::before {
  content: "\f07b";
}

.download-btn::before {
  content: "\f019";
}

.chat-btn:hover,
.files-btn:hover,
.download-btn:hover {
  background: linear-gradient(135deg, #2b6cb0, #2c5282);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(49, 130, 206, 0.35);
}

.chat-btn:active,
.files-btn:active,
.download-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(49, 130, 206, 0.25);
}

/* ============================= */
/* Theme Toggle */
/* ============================= */
.theme-toggle {
  display: flex;
  align-items: center;
}

.theme-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  color: inherit;
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.theme-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s ease;
}

.room-container.dark .theme-btn::before {
  background-color: rgba(255, 255, 255, 0.05);
}

.theme-btn:hover::before {
  transform: scale(1);
}

.theme-btn:hover {
  color: #3182ce;
}

.room-container.dark .theme-btn:hover {
  color: #90cdf4;
}

/* ============================= */
/* 3. Name Setup */
/* ============================= */
.name-setup {
  text-align: center;
  margin: 80px auto;
  width: 90%;
  max-width: 600px;
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.room-container.dark .name-setup {
  background-color: #1e1e1e;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.name-setup h1 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #2d3748;
  background: linear-gradient(135deg, #3182ce, #2b6cb0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.room-container.dark .name-setup h1 {
  background: linear-gradient(135deg, #90cdf4, #3182ce);
  background-clip: text;
  -webkit-background-clip: text;
}

.name-setup p {
  font-size: 18px;
  margin-bottom: 25px;
  color: #4a5568;
}

.room-container.dark .name-setup p {
  color: #e2e8f0;
}

.name-input {
  padding: 15px 20px;
  font-size: 18px;
  width: 80%;
  border-radius: 50px;
  border: 2px solid #e2e8f0;
  background-color: #f7fafc;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  color: #2d3748;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.room-container.dark .name-input {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
}

.name-input:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 2px 15px rgba(49, 130, 206, 0.2);
}

.room-container.dark .name-input:focus {
  border-color: #90cdf4;
  box-shadow: 0 2px 15px rgba(144, 205, 244, 0.2);
}

.submit-btn {
  padding: 15px 30px;
  font-size: 18px;
  background: linear-gradient(135deg, #38a169, #2f855a);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(56, 161, 105, 0.3);
}

.submit-btn:hover {
  background: linear-gradient(135deg, #2f855a, #276749);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(56, 161, 105, 0.4);
}

.submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(56, 161, 105, 0.3);
}

/* ============================= */
/* 4. Main Content (Code Editor) */
/* ============================= */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px 20px;
  overflow: hidden;
  position: relative;
}

.code-editor {
  font-size: 1rem;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  background-color: white;
  color: #2d3748;
  font-family: 'Fira Code', 'Courier New', monospace;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.code-editor.light {
  background-color: white;
  color: #2d3748;
}

.code-editor.dark {
  background-color: #1a202c;
  color: #e2e8f0;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Synchronization Overlay */
.yjs-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
  backdrop-filter: blur(5px);
  border-radius: 12px;
}

.room-container.dark .yjs-loading-overlay {
  background-color: rgba(26, 32, 44, 0.9);
}

.yjs-loading-overlay p {
  font-size: 1.2rem;
  color: #2d3748;
  background-color: white;
  padding: 15px 30px;
  border-radius: 50px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 500;
}

.room-container.dark .yjs-loading-overlay p {
  color: #e2e8f0;
  background-color: #1a202c;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

/* ============================= */
/* 5. Typing Indicator */
/* ============================= */
.typing-indicator {
  margin: 10px 0;
  font-style: italic;
  color: #718096;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px 20px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.typing-indicator::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #38a169;
  border-radius: 50%;
  margin-right: 10px;
  animation: pulse 1.5s infinite;
}

.room-container.dark .typing-indicator {
  color: #a0aec0;
  background-color: rgba(26, 32, 44, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.typing-indicator p {
  margin: 0;
}

/* ============================= */
/* 6. Chat Box Sidebar */
/* ============================= */
.chat-box {
  position: fixed;
  right: -350px;
  top: 80px;
  width: 350px;
  height: calc(100vh - 200px);
  background-color: white;
  box-shadow: -5px 5px 25px rgba(0, 0, 0, 0.15);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px 0 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}

.chat-box.dark {
  background-color: #1a202c;
  color: #e2e8f0;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.chat-box.open {
  right: 0;
  box-shadow: -10px 5px 30px rgba(0, 0, 0, 0.2);
}

.close-btn {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  color: #4a5568;
}

.close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #e53e3e;
  transform: rotate(90deg);
}

.chat-box.dark .close-btn {
  background-color: rgba(255, 255, 255, 0.05);
  color: #a0aec0;
}

.chat-box.dark .close-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fc8181;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-track {
  background: transparent;
}

.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.chat-box.dark .messages::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.message {
  padding: 12px 16px;
  margin-bottom: 0;
  background-color: #edf2f7;
  border-radius: 18px;
  border-bottom-left-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  max-width: 85%;
  align-self: flex-start;
  word-break: break-word;
}

.message:nth-child(even) {
  background-color: #e6f7ff;
  border-radius: 18px;
  border-bottom-right-radius: 4px;
  align-self: flex-end;
}

.chat-box.dark .message {
  background-color: #2d3748;
  color: #e2e8f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-box.dark .message:nth-child(even) {
  background-color: #2c5282;
}

.message strong {
  color: #3182ce;
  margin-right: 5px;
}

.chat-box.dark .message strong {
  color: #90cdf4;
}

.chat-input {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 15px;
  border-radius: 50px;
  border: 2px solid #e2e8f7;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #f7fafc;
  color: #2d3748;
}

.chat-input:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 2px 10px rgba(49, 130, 206, 0.2);
}

.chat-box.dark .chat-input {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
}

.chat-box.dark .chat-input:focus {
  border-color: #90cdf4;
  box-shadow: 0 2px 10px rgba(144, 205, 244, 0.2);
}

.send-btn {
  padding: 12px 24px;
  background: linear-gradient(135deg, #3182ce, #2b6cb0);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(49, 130, 206, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.send-btn::before {
  content: "\f1d8";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.send-btn:hover {
  background: linear-gradient(135deg, #2b6cb0, #2c5282);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(49, 130, 206, 0.35);
}

.send-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(49, 130, 206, 0.25);
}

/* ============================= */
/* 7. Files Modal */
/* ============================= */
.files-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #2d3748;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  z-index: 1500;
  width: 90%;
  max-width: 600px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.files-modal.dark {
  background-color: #1a202c;
  color: #e2e8f0;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.files-modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #2d3748;
}

.files-modal.dark h2 {
  color: #e2e8f0;
}

.files-modal .file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e2e8f7;
  transition: all 0.3s ease;
  border-radius: 10px;
  margin-bottom: 10px;
}

.files-modal.dark .file-item {
  border-bottom: 1px solid #4a5568;
}

.files-modal .file-item:hover {
  background-color: #f7fafc;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.files-modal.dark .file-item:hover {
  background-color: #2d3748;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.files-modal .file-item:last-child {
  border-bottom: none;
}

.files-modal .delete-btn {
  background: linear-gradient(135deg, #e53e3e, #c53030);
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0 4px 10px rgba(229, 62, 62, 0.25);
  display: flex;
  align-items: center;
  gap: 6px;
}

.files-modal .delete-btn::before {
  content: "\f1f8";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.files-modal .delete-btn:hover {
  background: linear-gradient(135deg, #c53030, #9b2c2c);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(229, 62, 62, 0.35);
}

.files-modal .upload-section {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f7fafc;
  border: 1px dashed #e2e8f7;
}

.files-modal.dark .upload-section {
  background-color: #2d3748;
  border: 1px dashed #4a5568;
}

.files-modal .upload-section input {
  margin-bottom: 20px;
}

.files-modal .upload-section button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #38a169, #2f855a);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(56, 161, 105, 0.25);
  display: flex;
  align-items: center;
  gap: 8px;
}

.files-modal .upload-section button::before {
  content: "\f574";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.files-modal .upload-section button:hover {
  background: linear-gradient(135deg, #2f855a, #276749);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(56, 161, 105, 0.35);
}

.files-modal .upload-section button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(56, 161, 105, 0.25);
}

/* ============================= */
/* 8. Footer */
/* ============================= */
.footer {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px 20px;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 40px;
  backdrop-filter: blur(10px);
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
  color: #4a5568;
}

.room-container.dark .footer {
  background-color: rgba(26, 32, 44, 0.8);
  color: #e2e8f0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.room-container.dark .footer-content {
  color: #a0aec0;
}

.footer-content p {
  margin: 5px 0;
}

.contact-link {
  color: #E1306C;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
}

.contact-link:hover {
  background-color: rgba(225, 48, 108, 0.1);
  color: #C13584;
  transform: translateY(-2px);
}

.contact-link .fa-instagram {
  color: #E1306C;
  transition: all 0.3s ease;
  margin-left: 8px;
}

.contact-link:hover .fa-instagram {
  transform: scale(1.2) rotate(15deg);
}

.contact-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(225, 48, 108, 0.3);
}

/* ============================= */
/* 9. Loading Spinner */
/* ============================= */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5em;
  background-color: inherit;
  color: inherit;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3182ce;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgba(49, 130, 206, 0.2);
}

.room-container.dark .spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top: 4px solid #90cdf4;
  box-shadow: 0 5px 15px rgba(144, 205, 244, 0.2);
}

/* ============================= */
/* 10. Language Selector Styles */
/* ============================= */
.language-select {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.language-select:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.room-container.dark .language-select {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.room-container.dark .language-select:hover {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.language-select label {
  font-size: 14px;
  color: #2d3748;
  font-weight: 600;
  transition: color 0.3s ease;
}

.room-container.dark .language-select label {
  color: #e2e8f0;
}

.language-select select {
  font-size: 14px;
  padding: 8px 12px;
  border: 1px solid #e2e8f7;
  border-radius: 20px;
  background-color: white;
  color: #2d3748;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%232d3748' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 30px;
}

.language-select select:hover {
  border-color: #cbd5e0;
  background-color: #f7fafc;
}

.language-select select:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.3);
}

.room-container.dark .language-select select {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23e2e8f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
}

.room-container.dark .language-select select:hover {
  background-color: #4a5568;
  border-color: #718096;
}

.room-container.dark .language-select select:focus {
  border-color: #90cdf4;
  box-shadow: 0 0 0 3px rgba(144, 205, 244, 0.3);
}

.language-select select option {
  color: #2d3748;
  background-color: white;
  padding: 10px;
}

.room-container.dark .language-select select option {
  color: #e2e8f0;
  background-color: #2d3748;
}

/* ============================= */
/* 13. Notification Badge */
/* ============================= */
.chat-btn {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: linear-gradient(135deg, #e53e3e, #c53030);
  color: white;
  font-size: 0.7rem;
  border-radius: 20px;
  padding: 4px 8px;
  line-height: 1;
  display: inline-block;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(229, 62, 62, 0.4);
  animation: pulse 2s infinite;
}

/* ============================= */
/* 14. Animations */
/* ============================= */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ============================= */
/* 15. CodeMirror Custom Styles */
/* ============================= */
.room-container.light :global(.cm-editor) {
  background-color: white !important;
  color: #2d3748 !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.room-container.light :global(.cm-scroller) {
  background-color: white !important;
  font-family: 'Fira Code', 'Courier New', monospace !important;
  padding: 10px !important;
}

.room-container.light :global(.cm-activeLine) {
  background-color: #f7fafc !important;
  border-left: 3px solid #3182ce !important;
  padding-left: 5px !important;
  margin-left: -8px !important;
}

.room-container.light :global(.cm-cursor) {
  border-left: 2px solid #3182ce !important;
}

.room-container.light :global(.cm-selectionBackground) {
  background-color: rgba(66, 153, 225, 0.4) !important; /* Increased opacity */
}

.room-container.light :global(.cm-gutters) {
  background-color: #f7fafc !important;
  color: #718096 !important;
  border-right: 1px solid #e2e8f7 !important;
  padding-right: 5px !important;
}
.room-container.light :global(.cm-activeLine.cm-line .cm-selectionBackground) {
  background-color: rgba(56, 143, 215, 0.5) !important; /* Slightly darker blue with higher opacity */
}

.room-container.dark :global(.cm-activeLine.cm-line .cm-selectionBackground) {
  background-color: rgba(134, 195, 234, 0.5) !important; /* Slightly lighter blue with higher opacity */
}
.room-container.light :global(.cm-lineNumbers) {
  color: #718096 !important;
}

.room-container.light :global(.cm-foldedText) {
  background-color: #edf2f7 !important;
  color: #2d3748 !important;
}

/* Syntax Highlighting - Light Theme */
.room-container.light :global(.cm-keyword) {
  color: #805AD5 !important; /* Purple */
}

.room-container.light :global(.cm-string) {
  color: #38A169 !important; /* Green */
}

.room-container.light :global(.cm-comment) {
  color: #718096 !important; /* Gray */
  font-style: italic !important;
}

.room-container.light :global(.cm-number) {
  color: #DD6B20 !important; /* Orange */
}

.room-container.light :global(.cm-property) {
  color: #3182CE !important; /* Blue */
}

.room-container.light :global(.cm-definition) {
  color: #319795 !important; /* Teal */
  font-weight: bold !important;
}

/* Dark Theme CodeMirror Styles */
.room-container.dark :global(.cm-editor) {
  background-color: #1a202c !important;
  color: #e2e8f0 !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.room-container.dark :global(.cm-scroller) {
  background-color: #1a202c !important;
  font-family: 'Fira Code', 'Courier New', monospace !important;
  padding: 10px !important;
}

.room-container.dark :global(.cm-activeLine) {
  background-color: #2d3748 !important;
  border-left: 3px solid #90cdf4 !important;
  padding-left: 5px !important;
  margin-left: -8px !important;
}

.room-container.dark :global(.cm-cursor) {
  border-left: 2px solid #90cdf4 !important;
}

.room-container.dark :global(.cm-selectionBackground) {
  background-color: rgba(144, 205, 244, 0.4) !important; /* Increased opacity */
}


.room-container.dark :global(.cm-gutters) {
  background-color: #2d3748 !important;
  color: #a0aec0 !important;
  border-right: 1px solid #4a5568 !important;
  padding-right: 5px !important;
}

.room-container.dark :global(.cm-lineNumbers) {
  color: #a0aec0 !important;
}
.room-container.dark :global(.cm-line:last-of-type .cm-selectionBackground),
.room-container.dark :global(.cm-line:last-child .cm-selectionBackground) {
  background-color: #90cdf4 !important;
}
.room-container.dark :global(.cm-foldedText) {
  background-color: #2d3748 !important;
  color: #e2e8f0 !important;
}

/* Syntax Highlighting - Dark Theme */
.room-container.dark :global(.cm-keyword) {
  color: #B794F4 !important; /* Light Purple */
}

.room-container.dark :global(.cm-string) {
  color: #9AE6B4 !important; /* Light Green */
}

.room-container.dark :global(.cm-comment) {
  color: #A0AEC0 !important; /* Light Gray */
  font-style: italic !important;
}

.room-container.dark :global(.cm-number) {
  color: #FBD38D !important; /* Light Orange */
}

.room-container.dark :global(.cm-property) {
  color: #90CDF4 !important; /* Light Blue */
}

.room-container.dark :global(.cm-definition) {
  color: #81E6D9 !important; /* Light Teal */
  font-weight: bold !important;
}

/* ============================= */
/* 16. Responsive Design */
/* ============================= */
@media (max-width: 968px) {
  .header {
    padding: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .header h1 {
    font-size: 1.4rem;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .logo-container {
    flex-basis: auto;
  }

  .syncrolly-logo {
    max-width: 40px;
  }

  .chat-toggle, 
  .theme-toggle,
  .language-select {
    flex-basis: auto;
  }

  .toggle-btn, 
  .chat-btn, 
  .files-btn, 
  .download-btn {
    font-size: 0.8rem;
    padding: 8px 15px;
  }

  .chat-box {
    width: 300px;
    height: calc(100vh - 180px);
  }

  .chat-box.open {
    right: 10px;
  }

  .code-editor {
    padding: 15px;
  }

  .language-select {
    padding: 5px 10px;
  }

  .language-select label {
    display: none;
  }

  .language-select select {
    padding: 8px;
    font-size: 0.8rem;
  }

  .name-setup {
    padding: 30px;
    margin: 40px auto;
  }

  .name-setup h1 {
    font-size: 24px;
  }

  .name-input {
    width: 90%;
  }

  .footer {
    padding: 20px;
  }
}

@media (max-width: 730px) {
  .header {
    padding: 10px;
    justify-content: center;
    gap: 8px;
  }

  .header h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .syncrolly-logo {
    max-width: 30px;
  }

  .toggle-btn, 
  .chat-btn, 
  .files-btn, 
  .download-btn {
    font-size: 0.7rem;
    padding: 6px 12px;
  }

  .theme-btn {
    font-size: 1rem;
    width: 35px;
    height: 35px;
  }

  .chat-box {
    width: 100%;
    height: 50vh;
    right: -100%;
    top: 0;
    border-radius: 0;
  }

  .chat-box.open {
    right: 0;
  }

  .language-select {
    padding: 4px 8px;
  }

  .language-select select {
    padding: 6px;
    font-size: 0.7rem;
    background-size: 12px;
    padding-right: 25px;
  }

  .main-content {
    padding: 10px;
  }

  .code-editor {
    padding: 10px;
    font-size: 0.9rem;
  }

  .notification-badge {
    font-size: 0.6rem;
    padding: 3px 6px;
    top: -5px;
    right: -5px;
  }

  .files-modal {
    width: 95%;
    padding: 20px;
  }

  .files-modal h2 {
    font-size: 20px;
  }

  .files-modal .file-item {
    padding: 10px;
  }

  .files-modal .delete-btn {
    padding: 6px 12px;
    font-size: 0.7rem;
  }

  .files-modal .upload-section {
    padding: 15px;
  }

  .files-modal .upload-section button {
    padding: 8px 16px;
    font-size: 0.8rem;
  }

  .footer-content {
    font-size: 0.7rem;
  }

  .contact-link {
    padding: 3px 6px;
  }
}
